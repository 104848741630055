<template>
        <section class="products-holder" :color="`${zamuPurple2}`" >   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm1 class="mt-10">
                         <v-card flat tile color="" width="90%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center px-0">
                                   <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading">Key Services</h3><br />
                                   <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading">Key <br />Services</h3><br />

                                    <v-card tile flat :color="`${zamuGrey1}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-white"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row align="center" justify="center">
                                <v-col cols="12"  lg="6" md="12" sm="12" xs="12" class="" v-for="(product, index) in products" :key="index">
                                    <v-card tile flat :color="`${product.color}`" min-height="243"  align="center" justify="center">
                                        <v-card-text class="section-services" align="left" justify="left">
                                            <v-row>
                                                <v-col cols="12"  lg="3" md="12" sm="12" xs="12" align="center" justify="center">
                                                    <v-card color="" flat tile class="transparent" height="100%" width="100%">
                                                        <v-card-text height="100%" width="100%" class="icon-holder text-center  d-flex align-center justify-center">
                                                          <v-icon class="mr-1">{{ product.icon }}</v-icon>
                                                        </v-card-text>
                                                    </v-card>

                                                </v-col>
                                                <v-col cols="12" lg="9" md="12" sm="12" xs="12" >
                                                    <h3 class="zamu-sub-heading product-header mt-6">{{ product.title }}</h3>
                                                    <p class="mt-4">{{ product.text }}</p>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>                                    
                                    </v-card>
                                </v-col>
                            </v-row>
                         </v-card>
                </v-flex>
            </v-layout>
        </section>
  
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: "products",
    mixins: [colorMixin, coreMixin],
     computed: {
        products () {
            const data = [
                {
                    image: 'Pie_chart.png',
                    title: 'Bank Transfer',
                    text: 'With Zamupay, you can send money directly to bank accounts  i.e. We support Pesalink, RTGS, EFT etc. ',
                    color: '#d29f2a',
                    icon: 'mdi-bank',

                },
                 {
                    image: 'Pie_chart.png',
                    title: 'Mobile Money',
                    text: 'Zamupay helps you pay your suppliers, vendors through mobile money networks like Mpesa, MTN, Vodacom, Tigo',
                    color: '#d29f2a',
                    icon: 'mdi-cellphone-nfc',

                },
                 {
                    image: 'Note.png',
                    title: `Collections                                                       `,
                    text: `We enable your business receive payments from multiple channels: Mpesa & Bank TransfersReceive money from your clients`,
                    color: '#ffffff',
                    icon: 'mdi-briefcase-plus-outline',
                },
                {
                    image: 'Note.png',
                    title: 'Settlements',
                    text: 'Withdraw your funds to your preferred accounts: Mobile money & Bank Accounts',
                    color: '#ffffff',
                    icon: 'mdi-briefcase-variant-outline',

                },
                {
                    image: 'Pie_chart.png',
                    title: 'Utility Payments',
                    text: 'Zamupay understands that Bills are a part of a business and we are here to help with that. You can pay for your utility bills such as Electricity, Water, Internet and even Statutory Bills.',
                    color: '#d29f2a',
                    icon: 'mdi-lightbulb-multiple-outline',
                },
                {
                    image: 'Pie_chart.png',
                    title: 'Mobile Money Integration',
                    text: 'We pride ourselves with our API Specification that enables easy integration between mobile money providers and organizations that want to interface with these providers ',
                    color: '#d29f2a',
                    icon: 'mdi-cellphone-cog',
                },
            ];
            return data;

        },

    },

}
</script>

<style>
.icon-holder {
    height: 100%;
    width: 100%;
}
.icon-holder .v-icon {
    font-size: 40px !important;
    color: #161616;
}

</style>